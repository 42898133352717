.form-errors {
    color: #C07275;
    border: 1px solid #C07275;
}

.form-feedback.form-success {
    color: #C07275;
    border: 1px solid #cedacc;
    font-weight: 300;
}

.form-feedback {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-Light";
    font-size: 0.85rem;
    width: 35%;
    left: 60%;
    height: 38px;
    margin-top: 20px;
    border-radius: 3px;
    border: 1px solid #C07275;
    opacity: 0;
    transition: opacity 0.4s;
}

.form-feedback.form-feedback-visible {
    opacity: 1;
}

.form-btn {
    background-color: #C07275;
    color: white;
    width: 50%;
    text-align: center;
    border: none;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: "Montserrat-Light";    
    border: 1.5px solid #C07275;
    transition: 0.4s;
    transition: background-color 0.4s;
}

.form-btn:hover {
    background-color: white;
    color: #C07275;
}

.form-btn:active {
    transition: all 0.15s;
    background-color: #C07275;
    color: white;
}

.form-heading {
    font-family: "BreeThinOblique";
    font-size: 2.2em;
    color: #C07275;
    margin: 10px;
    margin-top: 30px;
}

.form-wrapper {
    padding-left: 20%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inp {
    height: 40px;
    border: 1.5px solid #C07275;
    color: #C07275;
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: "Montserrat-Light";
    font-size: 0.7em;
    outline: none;
}

.inp:hover {
    border: 4px solid rgba(254, 96, 96, 0.5);
    height: 35px;
}
 
.inp-name {
    padding-left: 3%;
    padding-right: 1%;
    width: 43.5%;
}

.inp-mail {
    padding-left: 3%;
    padding-right: 1%;
    width: 43.5%;
}

.inp-comments {
    height: 90px;    
    padding-left: 3%;
    padding-right: 1%;
    width: 96%;
    resize: none;
    padding-top: 10px;
}

.inp.inp-comments:hover {
    height: 85px;
}

@keyframes fade-out {
    from {opacity: 1;}
    to {opacity: 0;}
};