.m-contact-teaser {
    width: 100%;
    display: flex;
    position: relative;
}

.m-contact-teaser-text {
    margin: 15px 0px 0px 15px;
    position: absolute;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: "Montserrat-Light";
    font-size: 1.6rem;
}

.m-contact-teaser-text > p {
    margin: 5px;
}

.m-contact-teaser-text > a {
    margin: 5px;
    text-decoration: none;
    color: inherit;
}

.m-contact-teaser-heading {
    font-size: 1.8rem;
}