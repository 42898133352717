.m-main-teaser {
    width: 100%;
    position: relative;
    display: flex;
}

.m-main-text {
    position: absolute;
    left: 47%;
    width: 50%;
    height: 90%;
    top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "BreeThinOblique";
    color: white;
    font-size: 3rem;
}

.m-main-text > p {
    margin: 8px;
}