.impressum2 {
    position: fixed;
    z-index: 999;
    width: 90%;
    height: 90%;
    background-color: white;
    left: 5%;
    top: 5%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-Light";
    box-shadow: 15px 15px 15px rgb(0, 0, 0, 0.3);
    overflow: hidden;
    overflow-y: scroll;
}

.impressum-text2 > hr {
    border-top: 1px solid black;
}

.impressum-header2 {
    font-size: 2em;
    align-self: center;
}

.impressum-text2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.overlay2 {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.4);
    z-index: 555;
}