.m-img-teaser {
    position: relative;
    width: 100%;
    display: flex;
}

.m-img-teaser > img {
    z-index: 2;
    transition: all 4s;
}

.transparent {
    opacity: 0;
}
.m-img-teaser-hidden-text {
    position: absolute;
    background-color: #C07275;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-family: "Montserrat-Light";
    font-size: 1.8rem;
    width: 100%;
    overflow: hidden;
}

.m-img-teaser-hidden-text > p {
    width: 80%;
    font-size: 1.6rem;
    text-align: center;
    top: 2em;
}

.m-img-teaser-hidden-text.v2 > p {
    width: 80%;
    font-size: 1.35rem;
    text-align: center;
    position: relative;
    top: 1em;
}

.m-img-teaser-content {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #C07275;
    font-size: 3rem;
    font-family: "BreeThinOblique";
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.m-img-teaser-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 12rem;
    transition: all 4s;
}

.m-img-teaser-text-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5px;
    transition: all 4s;
}

.m-img-teaser-text-2 > p {
    margin: 5px;
}

.m-img-teaser-text > p {
    margin: 5px;
}