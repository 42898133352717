.iwt-container {
    max-width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
}

.iwt-text {
    padding-top: 2%;
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    z-index: 1;
    font-family: "Montserrat-Light";
    font-size: 0.7rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.iwt-left {
    align-self: start;
}

.iwt-right {
    align-self: flex-end,
}

.iwt-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}