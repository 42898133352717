.m-btn-container {
    display: flex;
    width: 90%;
    position: relative;
    left: 5%;
}

.m-contact-form-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.m-contact-form-inp {
    padding-left: 10px;
    font-size: 1.2rem;
}

.m-contact-form-inp:focus {
    outline: 1.5px solid #c07275;
}

.m-contact-form-name {
    height: 2rem;
    width: 45%;
}

.m-contact-form-email {
    height: 2rem;
    width: 45%;
}

.m-contact-form-message {
    resize: none;
    border: none;
    height: 5em;
    font-family: "Montserrat-Light";
    margin-bottom: 10px;
    padding-top: 10px;
}

.m-contact-form-wrapper{
    padding-top: 20px;
    position: relative;
    width: 90%;
    left: 5%;
    display: flex;
    flex-direction: column;
}

.m-contact-form-container {
    background-color: #cedacc;
    font-family: "Montserrat-Light";
    color: #c07275;
    width: 100%;
    margin-bottom: 50px;
}

.m-contact-form-greeting {
    font-family: "Montserrat-Light";
    font-size: 1.2rem;
}

.m-feedback-wrapper {
    position: relative;
    margin: auto;
    width: 90%;
    display: flex;
}

.m-form-btn {
    background-color: #C07275;
    color: white;
    width: 50%;
    text-align: center;
    border: none;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-family: "Montserrat-Light";
    font-size: 1.2rem;    
    border: 1.5px solid #C07275;
    transition: all 0.2s;
}

.m-form-btn:active {
    background-color: white;
    color: #c07275;
}

.m-form-errors {
    color: #C07275;
    border: 1px solid #C07275;
}

.m-form-feedback.m-form-success {
    color: black;
    border: 1px solid #cedacc;
    font-weight: 300;
}

.m-form-feedback {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-family: "Montserrat-Light";
    font-size: 1.2rem;
    width: 100%;
    height: 38px;
    border-radius: 3px;
    border: 1px solid #C07275;
    opacity: 0;
    transition: opacity 0.4s;
}

.m-form-feedback.m-form-feedback-visible {
    opacity: 1;
}

.m-form-row {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 0;
}

.m-form-row > input {
    border: none;
    font-family: "Montserrat-Light";
}