.m-header {
    display: flex;
    position: relative;
    background-color: #cedacc;
    justify-content: space-between;
    width: 95%;
    padding-left: 2.5%;
    height: 12vh;
}

.m-header-text {
    color: #AA6865;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "BreeThinOblique";
    font-size: 3rem;
    line-height: 85%;
}

.m-header-text > p {
    margin: 5px;
}

.m-nav-list {
    height: 0;
    width: 100%;
    transition: all 1.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: "Montserrat-Light";
}

.m-nav-list.m-nav-list-active {
    height: 28rem;
    padding-top: 30px;
    padding-bottom: 30px;
}

.m-nav-list > div {
    margin: 0.5em;
    font-size: 2.5rem;
}
