.mtf-container {
    width: 90%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat-Light";
    background-color: white;
    font-size: 1.5rem;
}

.mtf-title {
    font-size: 2.5rem;
    margin: 5px;
    font-family: "BreeThinOblique";
    color: #c07275;
}

.mtf-container > p {
    margin: 5px;
}

.read-on.mtf-hidden {
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

.mtf-rest {
    max-height: 20px;
    transition: all 1.2s ease-in-out;
    overflow: hidden;
}


.mtf-rest.expand {
    max-height: 80rem;
}


.mtf-rest > p {
    margin: 5px;
}

.read-less {
    color: #C07275;
    margin: 5px;
    cursor: pointer;
    font-size: 0.8em;
    transition: all 1.2s;
}

.read-on {
    color: #C07275;
    margin: 5px;
    cursor: pointer;
    font-size: 0.8em;
    visibility: inherit;
    transition: visibility 0s 1.2s, height 0s;
    height: 15px;
}


