.contact-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    transition: 0.4s ease;
    border: solid rgba(26, 26, 26, 1) 1px;
    height: 2.5rem;
    width: 14rem;
    margin-top: 50px;
    margin-bottom: 50px;
    text-decoration: none;
    color: inherit;
}

.contact-button:hover {
    cursor: pointer;
    background-color: #AA6865;
}

.button-text {
    font-family: "Montserrat-Light";
    font-size: 0.8rem;
    white-space: nowrap;
    text-decoration: none;
}

.white {
    border-color: white;
    color: white;
}

.big {
    height: 3.2rem;
    width: 16rem;
    font-size: 0.80rem;
}