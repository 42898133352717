.impressum {
    position: fixed;
    z-index: 999;
    width: 90%;
    height: 90%;
    background-color: white;
    left: 5%;
    top: 5%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-Light";
    box-shadow: 15px 15px 15px rgb(0, 0, 0, 0.3)
}

.impressum-text > hr {
    border-top: 1px solid black;
}

.impressum-header {
    font-size: 2em;
    align-self: center;
}

.impressum-text {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.4);
    z-index: 555;
}