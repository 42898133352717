.m-nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.2s;
}

.m-nav.active {
    opacity: 1;
}

.m-nav > hr {
    border: 0;
    border-top: 2px solid #c07275;
    width: 70%;
    margin: 6px;
}

.m-nav-wrapper {
    position: relative;
    width: 20vw;
}