.m-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    color: white;
    margin-bottom: 30px;
}


.m-footer > div {
    font-family: "Montserrat-Light";
    margin: 0 7.5px 0 7.5px;
    color: white;
    font-style: italic;
    font-size: 1.5rem;
}

.m-datenschutz {
    font-family: "Montserrat-Light";
    margin: 0 7.5px 0 7.5px;
    color: white;
    font-style: italic;
    font-size: 1.5rem;
}

.m-impressum {
    position: relative;
    width: 90%;
    padding: 0 5% 0 5%;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    font-family: "Montserrat-Light";
    transition: 1.2s;
}

.m-impressum > p {
    font-family: "Montserrat-Light";
    font-size: 1.3rem;
}

.m-impressum > hr {
    border-top: 1px solid black;
}

.m-impressum-visible {
    max-height: 800px;
}