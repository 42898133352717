.mh-btn {
    position: relative;
    top: 5%;
    opacity: 0%;
    animation: fade-in 1.8s linear 3.6s normal 1 forwards;
}

.mh-ekl {
    display: flex;
    position: relative;
    bottom: 5%;
}

.mh-img {
    width: 100%;
    object-fit: cover;
}

.mh-subtitle {
    font-size: 1.8rem;
    opacity: 0;
    white-space: nowrap;
    animation: fade-in 1.8s linear 3.6s normal 1 forwards;
}

.mh-text {
    position: absolute;
    font-family: "BreeThinOblique";
    height: 100%;
    width: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3.7rem;
    color: white;
}

.mh-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
    font-size: 1rem;
}

.p-ekl {
    margin: 0;
    margin-right: 1rem;
    opacity: 0%;
}

.p-e {
    animation: fade-in-left 1.2s linear 1.2s normal 1 forwards;
}

.p-k {
    animation: fade-in-left 1.2s linear 2s normal 1 forwards;
}

.p-l {
    animation: fade-in-left 1.2s linear 2.8s normal 1 forwards;
}

@keyframes fade-in-left {
    from {opacity: 0;transform: translateX(-25px);}
    to {opacity: 1;transform: translate(0px);}  
}

@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}    
}