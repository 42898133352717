@font-face {
  font-family: "BreeThinOblique";
  src: local("BreeThinOblique"),
   url("./fonts/BreeThinOblique.otf") format("OpenType");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),url("./fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"), url("./fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 300;
}


a {
  text-decoration: none;
}

.App {
  min-height: 100%;
  background-attachment: scroll;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 16vh 1fr auto;
  grid-template-areas: 
    "navigation"
    "content"
    "footer";    
  background-color: #cedacc;
}

.header {
  display: flex;
  flex-direction: row;
  grid-area: navigation;
}

.content {
  grid-area: content;
}

.datenschutz {
  cursor: pointer;
}

.footer {
  background-color: #cedacc;
  display: flex;
  padding-bottom: 35px;
}

.ftr-item-heading {
  font-family: "BreeThinOblique";
  font-size: 2.2em;
  color: #C07275;
  margin: 10px;
  margin-top: 30px;
  cursor: pointer;
}

.footer-frame {
  position: relative;
  top: 4%;
  width: 45%;
  margin-left: 5%;
  display: flex;
}

.title {
  color: #C07275;
  width: 45%;
  padding-left: 5%;
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-family: "BreeThinOblique";
}

.navigation {
  height: 100%;
  width: 45%;
  padding-right: 5%;
}

.navbar {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.nav-item {
  color: white;
  font-size: 0.8rem;
  font-family: "Montserrat-Light", serif;
  font-weight: 900;
}

.nav-item.active {
  color: #AA6865
}

.nav-item:hover {
  color: #AA6865;
  cursor: pointer;
}

.main{  
  height: 130vh;
  width: 100vw;
  background-image: url(./images/Foto_Oben.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.heading {
  position: absolute;
  font-family: "BreeThinOblique", serif;
  font-size: 4.25rem;
  top: 25%;
  color: white;
}

.main-button {
  position: absolute;
  top: 60%;
  left: 62%;
}

#heading-1 {
  left: 50%;
  opacity: 0;
  animation: fade-in 1.2s linear 1.2s normal 1 forwards;
}

#heading-2 {
  left: 65%;
  opacity: 0;
  animation: fade-in 1.2s linear 2s normal 1 forwards;
}

#heading-3 {
  left: 81%;
  opacity: 0;
  animation: fade-in 1.2s linear 2.8s normal 1 forwards;
}

.subheading {
  position: absolute;
  font-family: "BreeThinOblique", serif;
  font-size: 2rem;
  left: 55.5%;
  top: 45%;
  color: white;
}

.centered {
  display: flex;
  width: 100%;
  justify-content: center;
}

.content-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 85%;
  margin-left: 7.5%;
}

.content-frame-header {
  margin-top: 60px;
  margin-bottom: 30px;
  margin-left: 7.5%;
  font-size: 2rem;
  font-family: "BreeThinOblique", serif;
}

.cfh-right {
  margin-left: 52.7%;
}

.content-frame-text {
  width: 47%;
  font-family: "Montserrat-Light", serif;
  font-size:  0.7rem;
  flex-direction: column;
}

.content-frame-text:first-child {
  margin-top: 0px;
}

.content-frame-img {
  width: 47%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: relative;
}


.vision_img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.paragraph-header {
  margin-bottom: 10px;
  font-size: 2.5vw;
  font-family: "BreeThinOblique", serif;
  z-index: 3;
}

.img-container {
  position: relative;
  height: 400px;
  width: 60%;
}

#sfs {
  position: absolute;
  background-size: contain;
  height: 100%;
  width: 100%;
  transition: 0.8s;
}

.sfs-img {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  transition: 0.8s;
}

.sfs-img:hover{
  opacity: 0;
}

#sfs-text {
  background-color: #C07275;
  height: 100%;
  position: absolute;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: none;
  color: white;
  font-family: "Montserrat-Light", serif;
  font-size: 0.7rem;
}



@keyframes fade-in {
  from {opacity: 0;transform: translateX(-25px);}
  to {opacity: 1;transform: translate(0px);}  
}

.ftr-item-li-2{
  color: #C07275;
  font-family: "Montserrat-Light";
  cursor: pointer;
  margin: 4px;
  font-size: 0.85em;
  margin-left: 10px;
}

.ftr-item-li {
  color: #C07275;
  font-family: "Montserrat-Light";
  margin: 4px;
  font-size: 0.85em;
  margin-left: 10px;
}

.ftr-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 40px;
}

hr {
  border:none;
  border-top: 1px solid #C07275;
  width: 100%;
}




